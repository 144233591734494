import React from 'react';
import { theme } from '../../theme';
import { logos } from './logos';
import { Client } from './Client';

export const Clients: React.SFC<{}> = () => {
  return (
    <>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          [theme.media.medium]: {
            marginRight: '-2.5rem',
            marginLeft: '-2.5rem',
          },
          [theme.media.large]: {
            marginRight: '-5rem',
            marginLeft: '-5rem',
          },
        }}
      >
        {logos.map(({ id, name, url }) => {
          return (
            <div
              key={id}
              css={{
                padding: '10px',
                width: '50%',
                marginTop: '1rem',
                textAlign: 'center',
                [theme.media.small]: {
                  width: '33%',
                },
                [theme.media.medium]: {
                  width: '25%',
                },
              }}
            >
              <Client id={id} name={name} url={url} />
            </div>
          );
        })}
      </div>
    </>
  );
};
