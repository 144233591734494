import * as React from 'react';
import { Heading } from '../components/Text/Heading';
import { ArrowButton } from '../components/Button/ArrowButton';
import { Layout } from '../layouts';
import { Container } from '../components/Container';
import { AccentHeading } from '../components/Text/AccentHeading';
import { Clients } from '../components/Clients/Clients';
import { Text } from '../components/Text/Text';
import { Footer } from '../components/Footer';
import { Helmet } from 'react-helmet';
import { Head } from '../components/Head';

export interface ClientsPageProps {}

export interface ClientsPageState {}

export default class ClientsPage extends React.Component<
  ClientsPageProps,
  ClientsPageState
> {
  render() {
    return (
      <Layout>
        <Head
          title="Clients"
          description="Clients and Partners of The Palmer Group"
        />
        <Container padding={['all']}>
          <div
            css={{
              marginBottom: '6rem',
            }}
          >
            <AccentHeading>Who We Work With</AccentHeading>
            <Heading component="h1">Clients &amp; Partners</Heading>
            <Text
              size={0}
              css={{
                marginTop: '1rem',
                maxWidth: 600,
              }}
            >
              We feel privileged to work with some extraordinary organizations.
            </Text>
          </div>

          <div css={{ textAlign: 'center' }}>
            <div>
              <Clients />
              <div css={{ marginTop: '4rem' }}>
                <ArrowButton to="/contact">Work with us</ArrowButton>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </Layout>
    );
  }
}
